<template>
  <div style="width: 90%" class="mx-auto">
    <!-- <div v-if="currentWorkspace?.connected"> -->
    <v-row class="d-flex justify-end my-2 mx-8">
      <v-col cols="6"></v-col>
      <v-col cols="6" class="d-flex justify-end align-center">
        <CustomFilterMenu
          @update-comparison-number="onUpdateComparisonNumber"
          :customFiltersPayload="customFiltersPayload"
        />
        <custom-date-menu
          @selectedDate="dateFromMenu($event)"
          class="ml-4"
          style="width: 260px !important"
        />
        <!-- </v-col> -->

        <!-- Search Field -->
        <!-- <v-col cols="3">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          flat
          autocomplete="off"
          persistent-placeholder
          hide-details
          dense
          outlined
          class="text-no-wrap rounded-md"
        >
        </v-text-field>
      </v-col> -->
        <!-- Date Range Picker -->
        <!-- <v-col cols="2" class="pr-0"> -->
        <!-- <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Picker in menu"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              hide-details
              dense
              class="ml-3"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" scrollable class="ml-3">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu> -->
        <!-- </v-col> -->
        <!-- <v-col cols="1" class="pl-0"> -->
        <!-- Filter settings menu -->
        <v-menu offset-y :close-on-content-click="false" v-model="filterMenu">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="settings-btn px-0" text v-bind="attrs" v-on="on">
              <SettingsButton />
            </v-btn>
          </template>
          <v-container class="settings">
            <v-sheet
              class="f-inter fs-14 fw-600 mt-2 mb-3"
              style="color: var(--v-lightPurple-base)"
              >Default</v-sheet
            >
            <v-row no-gutters>
              <v-col cols="6" v-for="(item, index) in headers" :key="index">
                <v-checkbox
                  class="mt-0 mb-3"
                  :label="item.text"
                  hide-details
                  v-model="columnsToAdd"
                  :value="item.value"
                  :ripple="false"
                ></v-checkbox>
              </v-col>
            </v-row>
            <div class="d-flex flex-row-reverse mt-3">
              <v-btn class="custom-btn ml-3" @click="addColumns">
                <span class="fs-12 fw-500 f-inter text-none lightPurple--text"
                  >Save</span
                >
              </v-btn>
              <v-btn
                text-transform="none"
                class="custom-btn ml-3"
                @click="resetCheckbox"
                ><span class="fs-12 fw-500 f-inter text-none subHeading--text"
                  >Cancel</span
                ></v-btn
              >
            </div>
          </v-container>
        </v-menu>
        <!-- <v-select
          v-model="selectedColumns"
          :items="headerOptions"
          item-text="text"
          item-value="value"
          label="Select Columns"
          multiple
          outlined
          hide-details
          dense
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 1">
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text caption"
              >(+{{ visibleHeaders.length - 1 }} others)</span
            >
          </template>
        </v-select> -->
      </v-col>
    </v-row>

    <v-row class="d-flex justify-end mt-0 mx-10">
      <v-col cols="2" class="text-end">
        <Button
          @button-clicked="fetchTrialBalance"
          :loader="loader"
          class="mr-2"
        >
          <template v-slot:buttonHeading>
            <p class="ma-0 pa-0 primary--text" style="letter-spacing: -0.3px">
              Update
            </p>
          </template>
        </Button>
      </v-col>
    </v-row>

    <Loader v-if="loader" />
    <!-- Data Table -->
    <!-- :headers="visibleHeaders" -->
    <v-row
      v-else
      class="text-center ma-0 mt-2"
      :style="
        cols.length >= 5
          ? 'padding: 5px 0 5px 20px !important'
          : 'padding: 0 !important'
      "
    >
      <v-col cols="12" class="ma-0 pa-0">
        <v-data-table
          hide-default-header
          :items="trialBalance"
          :loading="loader"
          :search="search"
          fixed-header
          height="68vh"
          dense
          hide-default-footer
          style="width: 90% !important; margin: auto !important"
        >
          <template slot="header">
            <thead>
              <tr>
                <th
                  class="lightPurple"
                  v-for="(header, headerKey) in cols"
                  :key="headerKey"
                >
                  <span class="white--text"> {{ header.text }}</span>
                </th>
              </tr>
            </thead>
          </template>
          <template #body="">
            <tbody
              v-for="(mainCategory, mainKey) in trialBalance"
              :key="mainKey"
            >
              <tr>
                <td
                  class="white"
                  :class="
                    column === 'debit' || column === 'credit'
                      ? 'text-end'
                      : 'text-start'
                  "
                  style="
                    border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
                  "
                  v-for="(column, columnKey) in selectedColumns"
                  :key="columnKey"
                >
                  <span v-if="column === 'debit'"
                    >{{ mainCategory[column] | handleNegativeValue }}
                  </span>
                  <span v-else-if="column === 'credit'"
                    >{{ mainCategory[column] | handleNegativeValue }}
                  </span>
                  <span v-else>{{ mainCategory[column] }}</span>
                </td>

                <td
                  class="text-start white"
                  style="
                    border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
                  "
                  v-for="(trialBalance, trialBalanceKey) in newTrialBalance"
                  :key="trialBalanceKey + 'A'"
                >
                  {{
                    (trialBalance?.balances[mainKey]?.debit -
                      trialBalance?.balances[mainKey]?.credit)
                      | handleNegativeValue
                  }}
                </td>
              </tr>
            </tbody>
          </template>
          <!-- <template v-slot:[`item.debit`]="{ item }">
            <span>{{ item.debit.toLocaleString() }}</span>
          </template>
          <template v-slot:[`item.credit`]="{ item }">
            <span>{{ item.credit.toLocaleString() }}</span>
          </template> -->
        </v-data-table>
      </v-col>
    </v-row>
    <!-- </div> -->
    <!-- <no-software-connected v-if="!currentWorkspace?.connected" /> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "../../../services/Api.js";
import CustomFilterMenu from "../common/CustomFilterMenu.vue";
import Button from "../../common/button/index.vue";
import Loader from "../../common/xero-form/Loader.vue";
import CustomDateMenu from "../common/CustomDateMenu.vue";
import SettingsButton from "../../../assets/svg-icons/transaction-page-setings.vue";
// import NoSoftwareConnected from "../../common/connect-software/NoSoftwareConnected.vue";
export default {
  components: {
    CustomFilterMenu,
    Button,
    Loader,
    SettingsButton,
    CustomDateMenu,
    // NoSoftwareConnected,
  },
  data() {
    return {
      filterMenu: false,
      columnsToAdd: [],
      customFiltersPayload: {
        type: "days",
        value: 0,
        financialEndCheck: false,
      },
      date: "",
      loader: false,
      trialBalance: [],
      newTrialBalance: [],
      search: "",
      headers: [
        { text: "Code", value: "accountCode", visible: true },
        { text: "Name", value: "accountName", visible: true },
        { text: "Type", value: "accountType", visible: true },
        { text: "Class", value: "accountClass", visible: true },
        { text: "Debit", value: "debit", visible: true },
        { text: "Credit", value: "credit", visible: true },
      ],
      selectedColumns: [
        "accountCode",
        "accountName",
        "accountType",
        "accountClass",
        "debit",
        "credit",
      ],
      dateRange: [],
      startDate: null,
      endDate: null,
      startDateMenu: false,
      dates: [],
      menu: false,
    };
  },
  mounted() {
    // this.fetchTrialBalance();
    this.columnsToAdd = this.selectedColumns;
  },
  computed: {
    ...mapGetters({
      currentWorkspace: "auth/getCurrentWorkspace",
    }),
    cols() {
      let cols = [];
      for (let i = 0; i < this.selectedColumns.length; i++) {
        const foundHeader = this.headers.find(
          (element) => element.value === this.selectedColumns[i]
        );
        if (!foundHeader) continue;
        cols.push({
          text: `${foundHeader?.text}`,
          value: `${foundHeader?.value}`,
          width: 200,
          sortable: false,
        });
      }
      for (let i = 0; i < this.newTrialBalance.length; i++) {
        cols.push({
          text: `${this.newTrialBalance[i]?.endDate}`,
          value: `${this.newTrialBalance[i]?.endDate}`,
          width: 200,
          sortable: false,
        });
      }
      return cols;
    },
    headerOptions() {
      let data = this.headers.map((header) => ({
        text: header.text,
        value: header.value,
      }));
      data.splice(0, 2);
      return data;
    },
    visibleHeaders() {
      return this.headers.filter((header) =>
        this.selectedColumns.includes(header.value)
      );
    },
  },

  methods: {
    addColumns() {
      this.selectedColumns = this.columnsToAdd;
      this.filterMenu = false;
    },
    resetCheckbox() {
      this.columnsToAdd = [
        "accountCode",
        "accountName",
        "accountType",
        "accountClass",
        "debit",
        "credit",
      ];
      this.selectedColumns = [
        "accountCode",
        "accountName",
        "accountType",
        "accountClass",
        "debit",
        "credit",
      ];
      this.filterMenu = false;
    },
    onUpdateComparisonNumber(number) {
      this.customFiltersPayload.value = number;
    },
    toggleColumnVisibility(column) {
      column.visible = !column.visible;
    },
    dateFromMenu(val) {
      this.date = val;
      this.fetchTrialBalance();
    },

    async fetchTrialBalance() {
      try {
        this.loader = true;
        let url = `report/trial-balance/${this.currentWorkspace.workspaceId}`;
        this.startDate = null;
        this.endDate = this.date;

        if (this.startDate || this.endDate) {
          url += `?startDate=${this.startDate}&endDate=${this.endDate}`;
        }

        const response = await ApiService.GetRequest(
          url,
          this.customFiltersPayload
        );
        console.log(response.data, "BAL");
        this.trialBalance = response.data?.balances[0].balances;
        this.newTrialBalance = response.data?.balances.slice(1);
        console.log("here it is", this.newTrialBalance);
        this.loader = false;
      } catch (error) {
        this.loader = false;
        this.$showNotificationBar(
          "error",
          error.response?.data?.message ? error.response.data.message : error
        );
      }
    },
  },
};
</script>

<style scoped>
:deep .v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  font-size: 12px !important;
  color: rgba(0, 0, 0, 1) !important;
}
.settings {
  width: 342px;
  height: 100%;
  background-color: white;
}
.custom-btn {
  background-color: rgba(53, 16, 167, 0.05) !important;
  border-radius: 8px;
  min-height: 35px;
  line-height: 19px;
  color: var(--v-lightPurple-base) !important;
  padding: 10px 20px 10px 20px;
  box-shadow: none;
  margin-left: 10px;
}
.settings-btn:hover {
  color: white !important;
}
table > thead > tr > th:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 4 !important;
  min-width: 200px;
}
table > tbody > tr > td:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  min-width: 200px;
}

table > thead > tr > th:nth-child(2) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 200px;
  z-index: 4 !important;
  min-width: 200px;
}
table > tbody > tr > td:nth-child(2) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 200px;
  min-width: 200px;
}
</style>
